/* eslint-disable no-empty-pattern */
import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "./router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    uuid: "",
    uploadFiles: [],
    totalFiles: 0,
    sizeFiles: ""
  },
  getters: {
    token: state => {
      return state.token;
    }
  },
  mutations: {
    SET_TOKEN: (state, {
      token
    }) => {
      state.token = token;
    }
  },
  actions: {
    SIGN_IN: ({
      commit
    }, payload) => {
      let {
        password,
        nextRoute
      } = payload;
      let postData = {
        password: password
      }
      return new Promise((resolve, reject) => {
        axios({
            url: "/sign_in",
            data: postData,
            method: "POST"
          })
          .then(response => {
            commit("SET_TOKEN", {
              token: response.data.token
            });
            nextRoute === "" ? router.push({
              name: "upload"
            }) : router.push({
              name: "download",
              query: {
                dl: nextRoute
              }
            });
            resolve(response);
          })
          .catch(error => {
            alert("Falsches Passwort :(")
            reject(error);
          });
      });
    }
  },
  modules: {}
});