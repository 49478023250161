import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("./views/Login.vue")
  },
  {
    path: "/upload",
    name: "upload",
    component: () => import("./views/Upload.vue")
  },
  {
    path: "/download",
    name: "download",
    component: () => import("./views/Download.vue")
  },
  {
    path: '/**',
    name: '404',
    component: () => import('./views/404.vue'),
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path === "/upload") {
    if (store.state.token !== "") {
      next();
    } else {
      next({ name: "login" });
    }
  } else if (to.path === "/download") {
    if (store.state.token !== "") {
      next()
    } else {
      next({ name: "login", query: { dl: to.query.dl } })
    }
  } else {
    next();
  }
});

export default router;
