import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Toasted from 'vue-toasted';
import VueClipboard from 'vue-clipboard2'
import {
  VueSpinners
} from '@saeris/vue-spinners'
import VModal from 'vue-js-modal'

import "@/assets/css/tailwind.scss";

Vue.config.productionTip = false;

VueClipboard.config.autoSetContainer = true // add this line

Vue.use(VueClipboard)
Vue.use(Toasted, {
  iconPack: 'material'
})
Vue.use(VueSpinners)
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");